// Servicios para casos médicos

import { BASE_URL, CaseStatus } from "../constants";
import { getAuthHeader } from "../utils";
import { addPerson, getPeopleMatchingCI } from "./people";

/**
 * @typedef MedicalCaseRequirement
 * @property {number} id
 * @property {number} requirement
 * @property {number} medical_case
 * @property {string} amount
 * @property {string} requirement_name
 * @property {string} type_requirement
 * @property {boolean} is_done
 */

/**
 * @typedef MedicalCaseInvoice
 * @property {number} id
 * @property {string} file
 * @property {number} medical_case
 * @property {string} file_name
 */

/**
 * @typedef MedicalCaseFile
 * @property {string} file
 * @property {number} medical_case
 * @property {string} file_name
 * @property {string} type_requirement Tipo de archivo
 */

/**
 * @typedef MedicalCase
 * @property {number} id
 * @property {number} patient
 * @property {number} holder
 * @property {number[]} diagnosis
 * @property {string} diagnosis_name
 * @property {import("./people").Person} holder_
 * @property {import("./people").Person} patient_
 * @property {number} medical_center
 * @property {string} medical_center_name
 * @property {string} status
 * @property {string} amount
 * @property {number} subsidiary
 * @property {string} subsidiary_name
 * @property {string} created_at Creation timestamp in UTC string
 * @property {MedicalCaseRequirement[]} requirements
 * @property {MedicalCaseInvoice[]} invoice
 * @property {MedicalCaseFile[]} files
 */


/**
 * Returns list of medical cases, filtered by medicalCenterId
 */
export async function getCases(page, pageSize=50, filters) {
  const queryParams = new URLSearchParams();
  if (page) {
    queryParams.append("page", page);
  }

  queryParams.append("page_size", pageSize);
  if (filters) {
    if (filters.status) {
      queryParams.append("status", filters.status);
    }
    if (filters.id){
      queryParams.append("id__contains", filters.id);
    }
  }

  // Fetch data
  const response = await fetch(`${BASE_URL}insurance/medical-case/?${queryParams.toString()}`, {
    headers: {
      ...getAuthHeader(),
    }
  });
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  /**@type {MedicalCase[]} */
  const data = await response.json();
  return data;
}

/**
 * Subscribe to medical cases updates
 * @param {function(MedicalCase[]): void} callback
 * @returns {() => void} Unsubscribe function
 */
export async function getCasesSubscribe() {
  const response = await fetch(BASE_URL + "insurance/medical-case-subscribe/", {
    headers: {
      ...getAuthHeader(),
    }
  });
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  /**@type {MedicalCase[]} */
  const data = await response.json();
  return data;
}

/**
 * Get case detail from ID
 * @param {number} caseId 
 */
export async function getCaseById(caseId) {
  const response = await fetch(`${BASE_URL}insurance/medical-case/${caseId}/`, {
    headers: {
      ...getAuthHeader(),
    }
  });
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  /**@type {MedicalCase} */
  const data = await response.json();
  return data;
}

/**
 * Update status of medical case
 * @param {object} params
 * @param {number} caseId
 * @param {{ status: string }} data
 */
export async function updateCaseStatus({ caseId, data }) {
  try {
    // console.log(caseId, data);
    const response = await fetch(`${BASE_URL}insurance/medical-case/${caseId}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(JSON.stringify(json));
    }
    return { type: "success", message: "Caso actualizado correctamente." };
  } catch (error) {
    console.error("Error updating case: ", error);
    return { type: "error", message: "Error actualizando el estado del caso" };
  }
}

/**
 * Update the status of multiple medical cases
 * @param {object} params
 * @param {number[]} params.caseIds
 * @param {string} params.status
 */
export async function updateMultipleCasesStatus({ caseIds, status }) {
  try {
    await Promise.allSettled(caseIds.map((caseId) => updateCaseStatus({ caseId, data: { status } })));
    return { type: "success", message: "Estados de los casos actualizados correctamente." };
  } catch (error) {
    console.error("Error updating cases: ", error);
    return { type: "error", message: "Error actualizando los estados de los casos" };
  }
}

/**
 * 
 * @param {object} params
 * @param {number} params.caseId
 * @param {{ id: number, requirement: number, is_done: boolean }[]} params.doneRequirements 
 */
export async function updateCaseRequirementsDone({ caseId, doneRequirements }) {
  try {
    const response = await fetch(`${BASE_URL}insurance/medical-case/${caseId}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({ requirements: doneRequirements, status: CaseStatus.ATENDIDO }),
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Requerimientos marcados como completados." };
  } catch (error) {
    console.error("Error updating case: ", error);
    return { type: "error", message: "Error marcando requerimientos como completados" };
  }
}

/**
 * Returns date in format Y-m-d
 * @param {string | number} day 
 * @param {string | number} month 
 * @param {string | number} year 
 */
// function formatBirthDate(day, month, year) {
//   if (day < 10) {
//     day = "0" + day;
//   }
//   if (month < 0) {
//     month = "0" + month;
//   }
//   return `${year}-${month}-${day}`;
// }

/**
 * CREAR CASO - NUEVO FORMATO
 * {
    "patient": 1,
    "holder": 1,
    "diagnosis": [1],
    "medical_center": 1,
    "status": "pendiente",
    "subsidiary":1,
    "requirements": [{
        "requirement": 19
    }]
  }
 */

/**
 * Add new medical case
 * @param {object} data 
 * @param {object} data.patient 
 * @param {object} data.holder 
 * @param {object[]} data.requirements 
 */
export async function addCase({ patient, holder, requirements, duplicate=false }) {
  try {
    // Validations @todo
    if (!requirements || !Array.isArray(requirements) || requirements.length === 0) {
      throw new Error("Debe seleccionar al menos un requerimiento.");
    }
    
    // Create holder if necessary
    let holderId = null;
    if (holder.shouldCreateHolder) {
      const holderData = {
        ci: holder.ciType + holder.ci,
        name: holder.name,
        sex: holder.sex,
        birthdate: holder.birthDate.split("/").reverse().join("-"),
        phone: holder.phoneCode + holder.phoneNumber,
        subsidiary: patient.subsidiary,
        is_holder: true,
        holder: null,
        condition: holder.condition,
      }
      const holderResponse = await addPerson(holderData);
      if (holderResponse.type === "error") {
        return holderResponse;
      }
      holderId = holderResponse.personId;
    }
    if (holderId === null) {
      const holderMatch = await getPeopleMatchingCI(holder.ciType + holder.ci);
      holderId = holderMatch[0].id;
    }

    // Create patient if necessary
    let patientId = null;
    if (patient.shouldCreatePatient) {
      const patientData = {
        ci: patient.ciType + patient.ci,
        name: patient.name,
        sex: patient.sex,
        birthdate: patient.birthDate.split("/").reverse().join("-"),
        phone: patient.phoneCode + patient.phoneNumber,
        subsidiary: patient.subsidiary,
        is_holder: false,
        holder: holderId,
      };
      const patientResponse = await addPerson(patientData);
      if (patientResponse.type === "error") {
        return patientResponse;
      }
      patientId = patientResponse.personId;
    }

    // Find patient ID through CI
    if (!patient.isHolder && !patientId) {
      const patientMatch = await getPeopleMatchingCI(patient.ciType + patient.ci);
      patientId = patientMatch[0].id;
    }
    if (patient.isHolder || !patientId) {
      patientId = holderId;
    }

    // Add case
    const caseData = {
      patient: Number(patientId),
      holder: Number(holderId),
      diagnosis: patient.diagnosis,
      medical_center: Number(requirements[0].medicalCenter),
      status: "pendiente",
      subsidiary: Number(patient.subsidiary),
      requirements: requirements.map((req) => ({
        requirement: Number(req.requirement),
      })),
      duplicate
    };
    const response = await fetch(BASE_URL + "insurance/medical-case/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify(caseData),
    });
    if (response.status === 400){
      const json = await response.json();

      return { type: "error", message: json.message, code: json?.code };
    }
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Caso agregado correctamente.", data: await response.json() };
    
  } catch (error) {
    console.error("Error adding case: ", error.message);
    return { type: "error", message: error.message };
  }
}

/**
 * Delete invoice file from medical case
 * @param {object} params
 * @param {number} params.invoiceId
 */
export async function deleteMedicalCaseInvoice({ invoiceId }) {
  try {
    const response = await fetch(`${BASE_URL}insurance/medical-case-invoice/${invoiceId}/`, {
      method: "DELETE",
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      const json = await response.json();
      // console.log(json);
      throw new Error(JSON.stringify(json));
    }
    return { type: "success", message: "Factura eliminada correctamente.", id: invoiceId };
  } catch (error) {
    console.error("Error deleting invoice: ", error);
    return { type: "error", message: "Error eliminando la factura" };
  }
}
